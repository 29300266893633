import {
  BookingDTO,
  BookingsGroup,
  BookingStatus,
  ServiceTypes,
} from '../../types';

export const isCourse = (
  groupOrBooking?: BookingsGroup | BookingDTO,
): boolean => {
  const firstBooking =
    (groupOrBooking as BookingsGroup)?.bookings?.[0] ||
    (groupOrBooking as BookingDTO);

  return firstBooking?.service?.type === ServiceTypes.COURSE;
};

export const isPendingApproval = (group: BookingsGroup): boolean => {
  return group.bookings.some(
    (booking) => booking.status === BookingStatus.PENDING_APPROVAL,
  );
};

export const isWaitList = (group: BookingsGroup): boolean => {
  return group.bookings.some(
    (booking) => booking.status === BookingStatus.PENDING,
  );
};

export const isBookAnotherAble = (group: BookingsGroup): boolean => {
  return !!(
    !isCourse(group) &&
    group.bookings.length === 1 &&
    group.allowedActions?.bookAnother
  );
};

export const isRescheduleAble = (group: BookingsGroup): boolean => {
  return !!(
    group?.allowedActions?.reschedule &&
    group.bookings.every((booking) => {
      return (
        !booking.service?.isBookingApprovalFlow &&
        booking.status !== BookingStatus.PENDING &&
        booking.service?.type !== ServiceTypes.COURSE &&
        !booking.isDynamicPrice
      );
    })
  );
};

export const isCancelAble = (group: BookingsGroup): boolean => {
  return !!group?.allowedActions?.cancel;
};

export const isAvailableOnline = (group: BookingsGroup): boolean => {
  return (
    group.bookings.some((booking) => !!booking.videoConference) &&
    !isWaitList(group) &&
    !isPendingApproval(group)
  );
};

import React from 'react';
import Text, { TextType } from '../Text';
import { classes, st } from './Modal.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { Dialog } from 'wix-ui-tpa/cssVars';
import Button, { ButtonComponentProps } from '../Button';
import { useApplicationContext } from '../../../../core/hooks/useApplicationContext';
import { ModalDataHooks } from '../../Widget/datahooks';

export interface ModalProps {
  modalTitle?: string;
  modalSubtitle?: string;
  primaryButton?: ModalButton;
  secondaryButton?: ModalButton;
  onClose?: () => void;
  dataHook: string;
}

export type ModalButton = {
  component?: React.ReactElement<ButtonComponentProps>;
  context?: ModalButtonContext;
};

export interface ModalButtonContext {
  label: string;
  onClick?: () => void;
}

export const Modal: React.FC<ModalProps> = ({
  modalTitle,
  modalSubtitle,
  primaryButton,
  secondaryButton,
  onClose,
  dataHook,
}) => {
  const { actions } = useApplicationContext();
  const { isMobile } = useEnvironment();

  const renderButton = ({
    button,
    className,
    buttonDataHook,
  }: {
    button: ModalButton;
    className: string;
    buttonDataHook: string;
  }) => {
    if (button.component) {
      return button.component;
    }

    if (button.context) {
      return (
        <Button
          className={st(classes.button, className)}
          upgrade
          fullWidth={isMobile}
          onClick={button.context.onClick}
          data-hook={buttonDataHook}
        >
          {button.context.label}
        </Button>
      );
    }
  };

  const onCloseModal = () => {
    if (typeof onClose === 'function') {
      onClose();
    }

    actions.closeModal();
  };

  return (
    <Dialog
      isOpen
      notFullscreenOnMobile
      onClose={onCloseModal}
      contentClassName={classes.content}
      data-hook={dataHook}
    >
      <div className={classes.modalContent} data-hook={ModalDataHooks.Main}>
        {modalTitle && (
          <Text
            data-hook={ModalDataHooks.Title}
            tagName="h2"
            className={classes.title}
            type={TextType.Title}
          >
            {modalTitle}
          </Text>
        )}
        {modalSubtitle && (
          <div className={classes.runningText}>
            <Text data-hook={ModalDataHooks.Subtitle} type={TextType.Primary}>
              {modalSubtitle}
            </Text>
          </div>
        )}
        <div className={st(classes.buttonContainer, { isMobile })}>
          {secondaryButton &&
            renderButton({
              button: secondaryButton,
              className: classes.secondary,
              buttonDataHook: ModalDataHooks.SecondaryButton,
            })}
          {primaryButton &&
            renderButton({
              button: primaryButton,
              className: classes.primary,
              buttonDataHook: ModalDataHooks.PrimaryButton,
            })}
        </div>
      </div>
    </Dialog>
  );
};

import React, { FC } from 'react';
import { BookingsGroup } from '../../../../../../../types';
import BookingBodyContentRow from './BookingBodyContentRow';
import { BookingBodyContentDataHooks } from '../../../../datahooks';

export interface BookingBodyContentProps {
  group: BookingsGroup;
}

const BookingBodyContent: FC<BookingBodyContentProps> = ({ group }) => {
  const withServiceName = group.bookings.length > 1;

  const rows = group.bookings.map((booking, index) => {
    return (
      <div data-hook={BookingBodyContentDataHooks.Row}>
        <BookingBodyContentRow
          key={booking.bookingId}
          booking={booking}
          withAddress={index === 0}
          withServiceName={withServiceName}
          withDivider={index !== group.bookings.length - 1}
        />
      </div>
    );
  });

  return <div data-hook={BookingBodyContentDataHooks.Main}>{rows}</div>;
};

export default BookingBodyContent;

import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { isRescheduleAble } from '../../../../utils/bookInfo';
import { ActionButtonUiTypes, BookingsGroup } from '../../../../types';
import { useGroupHeaderGetters } from '../../../hooks/useGroupHeaderGetter';
import { CancellationModalDataHooks } from '../../../../components/MyBookings/Widget/datahooks';
import { Modal } from '../../../../components/MyBookings/Components/Modal';
import { useApplicationContext } from '../../../hooks/useApplicationContext';
import { Reschedule } from '../../../../components/MyBookings/Widget/ActionsButtons/Reschedule';
import { ModalFacadeProps } from '../..';

export interface CancellationModalProps {
  group: BookingsGroup;
}

export const CancellationModal: React.FC<
  CancellationModalProps & ModalFacadeProps
> = ({ group }) => {
  const { actions } = useApplicationContext();
  const { t } = useTranslation();
  const { getTitle } = useGroupHeaderGetters();

  const handlePrimaryButtonClick = () => {
    actions.clickOnCancel({
      group,
      handler: {
        successMessage: t(
          'app.my-bookings-widget.cancellation-modal.toast-success-message.label',
        ),
        errorMessage: t(
          'app.my-bookings-widget.cancellation-modal.toast-error-message.label',
        ),
      },
    });
    actions.closeModal();
  };

  const handleSecondaryButtonClick = () => {
    actions.closeModal();
  };

  const renderCancellationModalWithRescheduleContent = () => (
    <Modal
      dataHook={CancellationModalDataHooks.Reschedule}
      modalTitle={t(
        'app.my-bookings-widget.cancellation-modal-with-reschedule.title',
      )}
      modalSubtitle={t(
        'app.my-bookings-widget.cancellation-modal-with-reschedule.content',
      )}
      primaryButton={{
        context: {
          label: t(
            'app.my-bookings-widget.cancellation-modal-with-reschedule.primary-button.label',
          ),
          onClick: handlePrimaryButtonClick,
        },
      }}
      secondaryButton={{
        component: (
          <Reschedule group={group} type={ActionButtonUiTypes.BUTTON} />
        ),
      }}
    />
  );

  const renderCancellationModalContent = () => (
    <Modal
      dataHook={CancellationModalDataHooks.Cancellation}
      modalTitle={t('app.my-bookings-widget.cancellation-modal.title')}
      modalSubtitle={t('app.my-bookings-widget.cancellation-modal.content', {
        serviceName: getTitle(group),
      })}
      primaryButton={{
        context: {
          label: t(
            'app.my-bookings-widget.cancellation-modal.primary-button.label',
          ),
          onClick: handlePrimaryButtonClick,
        },
      }}
      secondaryButton={{
        context: {
          label: t(
            'app.my-bookings-widget.cancellation-modal.secondary-button.label',
          ),
          onClick: handleSecondaryButtonClick,
        },
      }}
    />
  );

  const renderGroupCancellationModalContent = () => (
    <Modal
      dataHook={CancellationModalDataHooks.GroupCancellation}
      modalTitle={t('app.my-bookings-widget.group-cancellation-modal.title')}
      modalSubtitle={t(
        'app.my-bookings-widget.group-cancellation-modal.content',
      )}
      primaryButton={{
        context: {
          label: t(
            'app.my-bookings-widget.group-cancellation-modal.primary-button.label',
          ),
          onClick: handlePrimaryButtonClick,
        },
      }}
      secondaryButton={{
        context: {
          label: t(
            'app.my-bookings-widget.group-cancellation-modal.secondary-button.label',
          ),
          onClick: handleSecondaryButtonClick,
        },
      }}
    />
  );

  const renderModalContent = () => {
    if (isRescheduleAble(group)) {
      return renderCancellationModalWithRescheduleContent();
    } else if (group.bookings.length > 1) {
      return renderGroupCancellationModalContent();
    } else {
      return renderCancellationModalContent();
    }
  };

  return (
    <div data-hook={CancellationModalDataHooks.Main}>
      {renderModalContent()}
    </div>
  );
};

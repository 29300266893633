import React, { FC } from 'react';
import { BookingDTO } from '../../../../../../../../types';
import BookingBodyContentAddress from './RowContents/BookingBodyContentAddress';
import BookingBodyContentServiceName from './RowContents/BookingBodyContentServiceName';
import BookingBodyContentStaffInfo from './RowContents/BookingBodyContentStaffInfo';
import BookingBodyContentActionLinks from './RowContents/BookingBodyContentActionLinks';
import BookingBodyContentParticipantInfo from './RowContents/BookingBodyContentParticipantInfo';
import BookingBodyContentDuration from './RowContents/BookingBodyContentDuration';
import {
  classes as gridLaoutClasses,
  st,
} from '../../../../../../../../core/gridayout/styles.st.css';
import { classes } from './BookingBodyContentRow.st.css';
import { BookingBodyContentRowDataHooks } from '../../../../../datahooks';
import { Divider } from 'wix-ui-tpa/cssVars';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export interface BookingBodyContentRowProps {
  booking: BookingDTO;
  withAddress?: boolean;
  withServiceName?: boolean;
  withDivider?: boolean;
}

const BookingBodyContentRow: FC<BookingBodyContentRowProps> = ({
  booking,
  withAddress,
  withServiceName,
  withDivider,
}) => {
  const { isMobile } = useEnvironment();
  return (
    <div className={classes.root}>
      <div
        className={st(gridLaoutClasses.container, classes.container)}
        data-hook={BookingBodyContentRowDataHooks.Main}
      >
        <div>
          {withAddress && (
            <div data-hook={BookingBodyContentRowDataHooks.Address}>
              <BookingBodyContentAddress booking={booking} />
            </div>
          )}
        </div>
        <div>
          {withServiceName && (
            <div data-hook={BookingBodyContentRowDataHooks.ServiceName}>
              <BookingBodyContentServiceName booking={booking} />
            </div>
          )}
          <BookingBodyContentStaffInfo booking={booking} />
          <BookingBodyContentDuration booking={booking} />
          <BookingBodyContentParticipantInfo booking={booking} />
          <BookingBodyContentActionLinks booking={booking} />
        </div>
        {!isMobile && (
          <div data-hook={BookingBodyContentRowDataHooks.EmptyGridColumn}></div>
        )}
        {/* Empty div for grid layout // TODO: remove when content is added to the third column */}
      </div>
      {withDivider && (
        <Divider
          data-hook={BookingBodyContentRowDataHooks.Divider}
          className={classes.divider}
        />
      )}
    </div>
  );
};

export default BookingBodyContentRow;

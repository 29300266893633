import { useEnvironment } from '@wix/yoshi-flow-editor';
import { TextButton, TextButtonProps } from 'wix-ui-tpa/cssVars';
import React from 'react';
import { classes, st } from './Link.st.css';

export interface LinkProps extends TextButtonProps {
  children: string;
}

const Link: React.FC<LinkProps> = (props) => {
  const { isMobile, isRTL } = useEnvironment();
  const { children, className, ...rest } = props;

  return (
    <TextButton
      className={st(
        classes.root,
        {
          isMobile,
          isRTL,
        },
        className,
      )}
      {...rest}
    >
      {children}
    </TextButton>
  );
};

export default Link;

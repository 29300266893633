import { Order, OrderTransactions } from '@wix/ambassador-ecom-v1-order/types';

export interface Bookings {
  groups: BookingsGroup[];
  relatedOfferings: ServiceDTO[];
  relatedStaff: StaffDTO[];
}

export type BookingsGroup = {
  allowedActions: AllowedActions;
  bookings: BookingDTO[];
  groupId?: string;
  order?: Order;
  transaction?: OrderTransactions;
};

export interface AllowedActions {
  cancel?: boolean;
  reschedule?: boolean;
  bookAnother?: boolean;
}

export interface ServiceDTO {
  currency: string;
  id: string;
  image: any;
  name: string;
  paymentType: string;
  price: number;
  priceText: string | null;
  isFree: boolean;
  type: ServiceTypes;
  isBookingApprovalFlow: boolean;
  urlName: string;
}

export interface StaffDTO {
  id: string;
  fullName: string;
  image: any;
}

export enum BookingStatus {
  CONFIRMED = 'CONFIRMED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  PENDING = 'PENDING',
}

export interface VideoConference {
  link: string;
  password?: string;
  description?: string;
}

export interface BookingDTO {
  allowedActions: AllowedActions;
  bookingId: string;
  contactId: string;
  end: number;
  status: BookingStatus;
  payment?: any;
  groupInstanceId: string;
  locationInfo: string;
  locationId?: string;
  numOfParticipants: number;
  offeringId: string;
  service?: ServiceDTO;
  payed?: number;
  parentOfferingId: string;
  staffId: string;
  start: number;
  pricingPlanName: string | null;
  bookedAs?: string;
  totalPrice?: number; // unit * numOfParticipants
  totalPriceAfterDiscount?: number; // unit * numOfParticipants - (discount/%discount)
  currency: string;
  videoConference?: VideoConference;
  videoConferenceLink?: string; // todo remove after GA
  staffInfo?: RelatedStaff;
  revision?: any;
  dynamicPriceInfo?: string;
  isDynamicPrice?: boolean;
  groupId?: string;
}

export interface RelatedStaff {
  id: string;
  fullName: string;
  image: any;
}

export enum ServiceTypes {
  GROUP = 'GROUP',
  INDIVIDUAL = 'INDIVIDUAL',
  COURSE = 'COURSE',
}

export interface VideoConference {
  link: string;
  password?: string;
  description?: string;
}

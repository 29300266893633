import React, { FC } from 'react';
import Text, { TextType } from '../../../../../../../../Components/Text';
import { BookingDTO } from '../../../../../../../../../../types';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { BookingBodyContentStaffInfoDataHooks } from '../../../../../../../datahooks';

export interface BookingBodyContentStaffInfoProps {
  booking: BookingDTO;
}

const BookingBodyContentStaffInfo: FC<BookingBodyContentStaffInfoProps> = ({
  booking,
}) => {
  const { t } = useTranslation();
  const staffName = booking.staffInfo?.fullName;

  if (!staffName) {
    return null;
  }

  return (
    <div data-hook={BookingBodyContentStaffInfoDataHooks.Main}>
      <Text
        type={TextType.Primary}
        data-hook={BookingBodyContentStaffInfoDataHooks.Text}
      >
        {t('app.my-bookings-widget.booking-content.staff-prefix', {
          staffName: booking.staffInfo?.fullName,
        })}
      </Text>
    </div>
  );
};

export default BookingBodyContentStaffInfo;
